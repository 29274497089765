import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/template-parts/hero"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <Hero page={{ hero: { title: '404' } }} />
      <section className="not-found-wrapper">
        <h2>
          We seem to have hit an obstacle.
        </h2>
        <p>
          While we resolve the problem, check here to explore our Blog + ﻿Podcasts or the Mission 22 Shop.
        </p>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
