import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import '../../scss/hero.scss'

const DefaultImage = ({ title }) => {
    const { allFile } = useStaticQuery(graphql`
        query DefaultImageQuery {
            allFile(filter: {name: {eq: "header"}}) {
                files: nodes {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                        )
                    }
                }
            }
        }
    `)
    const headerImage = {
        data: allFile?.files[0]?.childImageSharp?.gatsbyImageData,
        alt: 'Mission 22 Default Header'
    }
    return (
        <GatsbyImage style={{gridArea: "1/1"}} layout="fullWidth" image={headerImage.data} alt={title} />
    )
}

const Hero = ({ page }) => {
    const { hero } = page
    let image = false;
console.log(hero, 'her')
    if (hero?.backgroundImage) {
        image = getImage(hero.backgroundImage.localFile)
    }

    return (
        <section className={`hero hero-${page.slug}`} style={{minHeight: hero.largeHero ? '600px' : '450px'}}>
            <div className="background-image">
                {image ? (
                    <GatsbyImage style={{gridArea: "1/1"}} layout="fullWidth" image={image} alt={hero.title} />
                ) : (
                    <DefaultImage title={hero.title} />
                )}
            </div>
            <div className="overlay">
                    {hero.title !== null ? (
                        <h1>
                            {parse(hero.title)}
                        </h1>
                    ) : (
                        <h1>
                            {parse(page.title)}
                        </h1>
                    )}

                    {hero.content && parse(hero.content)}

                    {hero.button && hero.button.url && hero.button.title && (
                        <Link to={hero.button.url} className='button black'>
                            {hero.button.title}
                        </Link>
                    )}
            </div>
        </section>
    )
}

export default Hero
